<template>
  <div class="invoice member-table-container container_media">
    <div class="rowShow title-row">
      <p class="card-title">发票管理</p>
      <ul class="tab-model">
        <li :class="activeName === 'member' ? 'tab active' : 'tab'" @click="handleTabClick('member')">
          <span>会员</span>
        </li>
        <li :class="activeName === 'retail' ? 'tab active' : 'tab'" @click="handleTabClick('retail')">
          <span>零售</span>
        </li>
      </ul>
    </div>
    <div class="content myCenter_table">
      <div class="operation-container">

        <div>
          <span style="color: #888;">发票状态：</span>
          <el-select v-model="applyStatus" size="small" placeholder="请选择开票状态" clearable @change="handleChangeStatus">
            <el-option v-for="item in statuList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <el-button type="download" style="width: 190px" plain :disabled="isdrawBill" @click="handleDownload()">
          申请开票
        </el-button>
      </div>
      <el-table  v-if="activeName === 'member'" ref="multipleTable" :data="list" header-cell-class-name="table_header" :cell-style="TableRowStyle"
        @selection-change="handleSelectionChange">
        <div slot="selection">111</div>
        <el-table-column type="selection" width="50" align="center" :selectable="handleCheckSelectable">
        </el-table-column>
        <el-table-column label="序号" type="index" align="center" width="auto" />
        <el-table-column label="套餐名称" prop="goods_name" align="center" width="auto" />
        <el-table-column label="类型" width="auto" align="center">
          <template slot-scope="{ row }">
            {{ row.goods_type | transitionValToLabel(vipType)
            }}{{ row.goods_use_mode | transitionValToLabel(useModes) }}会员
          </template>
        </el-table-column>
        <el-table-column label="金额" prop="paid_amount" align="center" width="auto">
          <template slot-scope="{ row: { paid_amount } }">{{ paid_amount }}元</template>
        </el-table-column>
        <el-table-column label="创建时间" width="auto" prop="type" align="center">
          <template slot-scope="{ row: { start_at, status } }">
            <span v-if="status">
              {{ start_at | parseDate('yyyy-MM-dd') }}
            </span>
            <span v-else> - </span>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="auto" align="center">
          <template slot-scope="{ row: { apply_status } }">
            {{ Number(apply_status) | transitionValToLabel(invoiceStatus) }}
          </template>
        </el-table-column>
        <el-table-column label="说明" width="150" align="center">
          <template slot-scope="scope">
            <span style="overflow: hidden;
                        text-overflow: ellipsis;
                        cursor: default;
                        word-break: break-all" :title="scope.row.apply_check_reason">{{ scope.row.apply_check_reason ?
                          scope.row.apply_check_reason : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="left">
          <template slot-scope="{ row: { apply_status, pay_type, ...info } }">
            <el-button class="detail-text" type="text" v-if="Number(apply_status) === 2"
              @click="handleDownload(info)">
              重新开票
            </el-button>
            <el-button class="detail-text" type="text" v-else
             :disabled="
  pay_type === 'REGISTER_PAY' ||
  pay_type === 'WX_SEND_PAY' ||
  pay_type === 'WX_ACTIVE_PAY' ||
  (Number(apply_status) === 2 ||
    Number(apply_status) === 0 ||
    Number(apply_status) === 1)
" @click="handleDownload(info)">
              开票
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-table  v-if="activeName === 'retail'" ref="multipleTable" :data="retailList" header-cell-class-name="table_header" :cell-style="TableRowStyle"
        @selection-change="handleSelectionChange">
        <div slot="selection">111</div>
        <el-table-column type="selection" width="50" align="center" :selectable="handleCheckSelectable">
        </el-table-column>
        <el-table-column label="序号" type="index" align="center" width="auto" />
        <el-table-column label="商品名称" align="center" width="auto">
          <template slot-scope="{ row: { extend: { name } } }">
              {{ name }}
            </template>
        </el-table-column>
        <el-table-column label="类型" width="auto" align="center">零售</el-table-column>
        <el-table-column label="金额" prop="pay_amount" align="center" width="auto">
          <template slot-scope="{ row: { pay_amount } }">{{ Number(pay_amount / 100) }}元</template>
        </el-table-column>
        <el-table-column label="创建时间" width="auto" prop="type" align="center">
          <template slot-scope="{ row: { created_at } }">
            {{ created_at | parseDate('yyyy-MM-dd') }}
          </template>
        </el-table-column>
        <el-table-column label="状态" width="auto" align="center">
          <template slot-scope="{ row: { apply_status } }">
           {{ Number(apply_status) | transitionValToLabel(invoiceStatus) }}
          </template>
        </el-table-column>
        <el-table-column label="说明" width="150" align="center">
          <template slot-scope="scope">
            <span style="overflow: hidden;
                        text-overflow: ellipsis;
                        cursor: default;
                        word-break: break-all" :title="scope.row.apply_check_reason">{{ scope.row.apply_check_reason ?
                          scope.row.apply_check_reason : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="left">
          <template slot-scope="{ row: { apply_status, order_id } }">
            <el-button class="detail-text" type="text" v-if="Number(apply_status) === 2"
              @click="handleDownload({order_id})">
              重新开票
            </el-button>
            <el-button v-else class="detail-text" type="text" :disabled="Number(apply_status) != -1"
            @click="handleDownload({order_id})">
              开票
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <div class="vf-music-pagination">
        <Pagination v-if="total > 0" :hiddenPage="true" position="center" :limit.sync="pageParams.page_size"
          :page.sync="pageParams.page" @pagination="pagination" :total="total" />
      </div>
    </div>

    <ApplyInvoice ref="ApplyInvoice" :type="activeName" @success="handleInvoiceSuccess" />
  </div>
</template>

<script>
import ApplyInvoice from '@/views/member/components/ApplyInvoice'
import Pagination from '@/components/Pagination'
import { fetchUserVipsOrderList } from '@/api/user'
import { feachRetailOrderList } from '@/api/member'

import {
  ORDER_PAY_STATUS,
  USE_MODES,
  VIP_TYPE,
  INVOICE_STATUS
} from '@/enum/common'
export default {
  name: 'AuthorizedDownload',
  components: { ApplyInvoice, Pagination },
  data() {
    return {
      applyStatus: '',
      activeName: 'member',
      list: [],
      retailList: [],
      statuList: [{value: 2,label: '未开票'},{value: 1,label: '已开票'}],
      pageParams: {
        page: 1,
        page_size: 10
      },
      total: 0,
      isdisabled: false, // 是否已经领取会员 已经领取完的 无法去开发票
      isdrawBill: false, // 是否可以点击顶部开发票按钮
      vipType: [...Object.values(VIP_TYPE)],
      useModes: [...Object.values(USE_MODES)],
      invoiceStatus: [...Object.values(INVOICE_STATUS)],
      orderPayStatus: [...Object.values(ORDER_PAY_STATUS)],
      multipleSelection: []
    }
  },
  created() {
    this.getVipsList()
  },
  methods: {
    // 切换tab
    handleTabClick(key){
      this.activeName = key
      this.pageParams.page = 1
      this.applyStatus = ''
      if(key == 'member') this.getVipsList()
      if(key == 'retail') this.getRetailList()
    },
    TableRowStyle({ rowIndex }) {
      let tableRowStyle = "";
      if (rowIndex % 2 !== 0) {
        tableRowStyle = tableRowStyle + "background-color: #FAFAFA;border-radius: 2px;";
      }
      return tableRowStyle;
    },
    // 筛选开票状态
    handleChangeStatus(){
      this.pageParams.page = 1
      if(this.activeName == 'member') this.getVipsList()
      if(this.activeName == 'retail') this.getRetailList()
    },
    pagination(info) {
      this.pageParams.page = info.page
      this.pageParams.page_size = info.limit
      this.activeName == 'member' ? this.getVipsList() : this.getRetailList()
    },
    getRetailList(){
      feachRetailOrderList({
        pay_status: 4,
        is_invoice: this.applyStatus,
        ...this.pageParams
      }).then((res) => {
        if (res.code === 0) {
          this.retailList = res.data.list;
          this.total = res.data.total;
          this.isdrawBill = false
        }
      })
    },
    getVipsList() {
      fetchUserVipsOrderList({
        is_pay: 1,
        is_invoice: this.applyStatus,
        ...this.pageParams
      }).then((res) => {
        if (res.code === 0) {
          this.list = res.data.list;
          this.total = res.data.total;
          const isBill = this.list.every((ele) => {
            return ele.pay_type == 'REGISTER_PAY' || ele.pay_type == 'WX_SEND_PAY' || ele.pay_type == 'WX_ACTIVE_PAY'
          })
          this.isdrawBill = isBill
        }
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    /**
     * 返回是否能选中当前行
     * @param row
     */
    handleCheckSelectable(row) {
      return row.apply_status == '-1' && !(row.pay_type == 'REGISTER_PAY' || row.pay_type == 'WX_SEND_PAY' || row.pay_type == 'WX_ACTIVE_PAY')
    },
    handleInvoiceSuccess(type){
      if(type == 'member') this.getVipsList()
      if(type == 'retail') this.getRetailList()
    },
    /**
     * 申请开票
     * 2021-12-27 13:14:35
     * @author SnowRock
     */
    handleDownload(info) {
      let list = []
      if (info) {
        // 单个开票
        list.push(info);
        this.$refs.ApplyInvoice.handleOpenDialog(list)
      } else {
        // 多选
        if (this.multipleSelection.length <= 0) {
          this.$message.warning(`请选择${this.activeName == 'member' ? '套餐' : '订单'}`)
          return;
        }
        list = this.multipleSelection
        this.$refs.ApplyInvoice.handleOpenDialog(list)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title-row {
  margin: 10px 0 40px;
}
.rowShow {
  display: flex;
  align-items: center;
}
.card-title {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 20px;
  margin-right: 40px;
}
.tab-model {
  display: flex;
  align-items: center;
  cursor: pointer;

  .tab {
    // width: 92px;
    padding: 0 18px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background: #FFFFFF;
    border-radius: 16px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    margin-right: 20px;
  }

  .active {
    background: rgba(229, 42, 13, 0.1);
    font-weight: 600;
    color: #E52A0D;
  }
}
.invoice {
  padding: 40px 0;

  .operation-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    /deep/ {
      .el-button--download {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }
}
.el-select-dropdown__item {
  padding: 0 15px;
}
.content {
  @include size(100%, auto);
  padding: 24px 36px;
  border-radius: 4px;
  box-sizing: border-box;
  background: #FFFFFF;
  margin-top: 24px;

  .vf-music-pagination {
    margin-top: 40px;
  }
}
</style>
