<template>
  <el-dialog
    class="dialog-self"
    width="520px"
    :visible.sync="dialogVisible"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :append-to-body="true"
  >
    <div class="dialog-title-self" slot="title">
      <div class="title">申请开票</div>
    </div>
    <el-form
      ref="authorizedForm"
      :model="authorizedForm"
      :rules="authorizedRules"
      label-width="120px"
      size="small"
    >
      <el-form-item label="发票类型" prop="type">
        <el-radio-group v-model="authorizedForm.type">
          <el-radio label="2" value="2">电子增值税普通发票</el-radio>
          <el-radio label="1" value="1" :disabled="isPeople">电子增值专用发票</el-radio>
        </el-radio-group>
      </el-form-item>
      <!--<el-form-item label="发票金额" prop="invoicePrice">
        <el-input
          v-model="authorizedForm.invoicePrice"
          placeholder="请输入金额"
        />
      </el-form-item>-->
      <el-form-item label="发票抬头类型" prop="header_type">
        <el-radio-group v-model="authorizedForm.header_type" @change="handleHeaderTypeChange">
          <el-radio label="1" value="1">企业</el-radio>
          <el-radio label="2" value="2">个人</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="抬头名称" prop="name">
        <el-input
          v-model="authorizedForm.name"
          placeholder="请输入发票抬头名称"
        />
      </el-form-item>
      <el-form-item
        :label="isCompany ? '纳税人识别号' : '身份证号'"
        prop="taxer_number"
      >
        <el-input
          v-model="authorizedForm.taxer_number"
          :placeholder="`请输入${isCompany ? '纳税人识别号' : '身份证号'}`"
        />
      </el-form-item>
      <el-form-item v-if="isCompany && isPaper" label="公司注册地址" prop="reg_address">
        <el-input
          v-model="authorizedForm.reg_address"
          placeholder="请输入公司注册地址"
        />
      </el-form-item>
      <el-form-item v-if="isCompany && isPaper" label="注册手机号码" prop="reg_phone">
        <el-input
          v-model="authorizedForm.reg_phone"
          placeholder="请输入公司注册手机号码"
        />
      </el-form-item>
      <el-form-item v-if="isCompany && isPaper" label="开户行" prop="bank">
        <el-input v-model="authorizedForm.bank" placeholder="请输入开户行" />
      </el-form-item>
      <el-form-item v-if="isCompany && isPaper" label="银行卡" prop="account">
        <el-input v-model="authorizedForm.account" placeholder="请输入银行卡" />
      </el-form-item>
      <el-form-item v-if="isCompany && isPaper" label="一般纳税人资质" prop="path">
        <SingleImageUpload v-model="authorizedForm.path" accept="image/*" :isPublic="true"/>
        <a class="look" href="/licensePage" target="_blank">点击查看如何获得该资质认证凭证</a>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="authorizedForm.email" placeholder="请输入您的邮箱" />
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input
          v-model="authorizedForm.phone"
          placeholder="请输入您的手机号"
        />
      </el-form-item>
      <!-- <el-form-item v-if="isPaper" label="收件人姓名" prop="rcv_name">
        <el-input
          v-model="authorizedForm.rcv_name"
          placeholder="请输入收件人姓名"
        />
      </el-form-item>
      <el-form-item v-if="isPaper" label="地址" prop="address">
        <el-input
          v-model="authorizedForm.address"
          placeholder="请输入收货地址"
        />
      </el-form-item> -->
    </el-form>
    <div slot="footer">
      <el-button type="primary" @click="handleSubmitInfo">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { applyInvoiceForDownload, applyRetailInvoiceForDownload } from '@/api/member'
import SingleImageUpload from '@/components/UploadImage'
import { validEmail, validPhone } from '@/utils/validate'

export default {
  name: 'ApplyInvoice',
  components: { SingleImageUpload },
  props: {
    type: {
      type: String,
      default: ''
    },
  },
  data() {
    const { isCompany } = this
    const validatorEmail = (rule, value, callback) => {
      if (!validEmail(value)) {
        callback('请输入正确的邮箱')
        return
      }
      callback()
    }
    const validatorPhone = (rule, value, callback) => {
      if (!validPhone(value)) {
        callback('请输入正确的手机号')
        return
      }
      callback()
    }
    return {
      dialogVisible: false,
      authorizedForm: {
        type: '2',
        header_type: '1',
        invoicePrice: '',
        reg_address: '',
        reg_phone: '',
        name: '',
        bank: '',
        account: '',
        taxer_number: '',
        path: '',
        email: '',
        phone: '',
        // rcv_name: '',
        // address: ''
      },
      authorizedRules: {
        header_type: [
          { required: true, message: '请选择抬头类型', trigger: 'change' }
        ],
        invoicePrice: [
          { required: true, message: '请输入发票金额', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请输入抬头名称', trigger: 'blur' }],
        bank: [{ required: true, message: '请输入开户行', trigger: 'blur' }],
        reg_address: [
          { required: true, message: '请输入公司注册地址', trigger: 'blur' }
        ],
        reg_phone: [
          { required: true, message: '请输入公司注册手机号码', trigger: 'blur' }
        ],
        account: [
          { required: true, message: '请输入银行卡号', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择发票类型', trigger: 'change' }
        ],
        path: [
          { required: true, message: '请上传营业执照附件', trigger: 'change' }
        ],
        taxer_number: [
          {
            required: true,
            message: isCompany ? '请输入纳税人识别号' : '请输入身份证号',
            trigger: 'blur'
          }
        ],
        // rcv_name: [
        //   { required: true, message: '请输入收件人姓名', trigger: 'blur' }
        // ],
        phone: [
          { required: true, message: '请输入您的手机号', trigger: 'blur' },
          { validator: validatorPhone, trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入您的邮箱', trigger: 'blur' },
          { validator: validatorEmail, trigger: 'blur' }
        ],
        // address: [
        //   { required: true, message: '请输入您的详细地址', trigger: 'blur' }
        // ]
      },
      info: null
    }
  },
  computed: {
    isCompany() {
      return this.authorizedForm.header_type === '1'
    },
    isPeople() {
      return this.authorizedForm.header_type === '2'
    },
    isElectronic() {
      return this.authorizedForm.type === '2'
    },
    isPaper() {
      return this.authorizedForm.type === '1'
    }
  },
  methods: {
    /**
     * 处理打开模态框
     * 2021-12-17 16:12:14
     * @author SnowRock
     * @param info
     */
    handleOpenDialog(info) {
      this.info = info

      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.authorizedForm.resetFields()
        this.authorizedForm = {
          type: '2',
          header_type: '1',
          invoicePrice: '',
          reg_address: '',
          reg_phone: '',
          name: '',
          bank: '',
          account: '',
          taxer_number: '',
          path: '',
          email: '',
          phone: '',
          // rcv_name: '',
          // address: ''
        }
      })
    },
    /**
     * 处理关闭弹框
     * 2021-12-17 16:12:35
     * @author SnowRock
     */
    handleClose() {
      this.dialogVisible = false
    },
    handleHeaderTypeChange() {
      if (this.isPeople) {
        this.authorizedForm.type = '2'
      }
    },

    /**
     * 处理提交信息
     * 2021-12-17 16:23:51
     * @author SnowRock
     */
    handleSubmitInfo() {
      const vm = this
      vm.$refs.authorizedForm.validate(async (valid) => {
        if (!valid) return
        const { isPaper, isCompany } = vm
        const order_id = this.info.map((i) => i.order_id)
        const {
          header_type,
          bank,
          account,
          type,
          path,
          taxer_number,
          // rcv_name,
          name,
          phone,
          reg_address,
          reg_phone,
          email,
          // address
        } = vm.authorizedForm
        const params = {
          order_id,
          header_type,
          type,
          name,
          taxer_number,
          phone
        }
        // 企业
        if (isCompany && isPaper) {
          params.bank = bank
          params.account = account
          params.path = path
          params.reg_address = reg_address
          params.reg_phone = reg_phone
        }
        // 纸质发票
        // if (isPaper) {
        //   params.address = address
        //   params.rcv_name = rcv_name
        // }
        // // 电子发票
        // if (isElectronic) {

        // }
        params.email = email
        vm.submitLoading = true
        const fn = this.type == 'member' ? applyInvoiceForDownload : applyRetailInvoiceForDownload
        try {
          await fn(params)
          vm.$message.success('提交成功')
          vm.$emit('success', this.type)
          vm.handleClose()
        } catch (e) {
          console.log(e)
        }
        vm.submitLoading = false
      })
    }
  }
}
</script>

<style scoped>
  .look{
    color: #0061F3;
  }
</style>
